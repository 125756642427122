import React from 'react'
import PropTypes from 'prop-types'

import text from '../../config/text'

const config = require('../../config')

const Text = ({ children, language }) => {
  const key = children.replace(/\s/g, '-');
  const str = !!text[key] && !!text[key][language]
    ? text[key][language] 
    : `[${key}][${language}]${children}`;

  return (
    <span>{str}</span>
  )
}

Text.propTypes = {
  children: PropTypes.string.isRequired,  
  language: PropTypes.string.isRequired,
}

Text.defaultProps = {
  language: config.siteLanguage
}

export default Text
