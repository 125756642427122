import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Text } from '../components'

const config = require('../../config')

const Wrapper = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem 4rem;
  background-color: ${props => props.theme.colors.bg};
  z-index: 9000;
  margin-top: -3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  form {
    p {
      label,
      input {
        display: block;
      }
      input {
        min-width: 275px;
        margin-top: 0.5rem;
      }
      textarea {
        resize: vertical;
        min-height: 150px;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
`

class ContactForm extends Component {

  render() {
    const { language } = this.props;

    return (
      <Wrapper>
        <form name="contact-form" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
          <p>
            <label htmlFor="contact-name">
              <Text>Name</Text>
              <input name="name" id="contact-name" type="text" required />
            </label>
          </p>
          <p>
            <label htmlFor="contact-email">
              <Text>E-Mail</Text>
              <input name="email" id="contact-email" type="email" required />
            </label>
          </p>
          <p>
            <label htmlFor="contact-message">
              <Text>Your Message</Text>
              <textarea name="message" id="contact-message" required />
            </label>
          </p>
          <p>
            <Button>
              <Text>Send</Text>
            </Button>
          </p>
          <input type="hidden" name="form-name" value="contact-form" />
        </form>
      </Wrapper>
    )
  }
}

ContactForm.propTypes = {
  language: PropTypes.string.isRequired,  
}

ContactForm.defaultProps = {
  language: config.siteLanguage
}

export default ContactForm


// TODO: figure out how to include this at MDX files.
// TODO: also whether we need react component at all.

// const ContactForm = (language) => (
//   <Wrapper>
//     <form name="contact-form" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
//       <p>
//         <label htmlFor="contact-name">
//           <Text>Name</Text>
//           <input name="name" id="contact-name" type="text" required />
//         </label>
//       </p>
//       <p>
//         <label htmlFor="contact-email">
//           <Text>E-Mail</Text>
//           <input name="email" id="contact-email" type="email" required />
//         </label>
//       </p>
//       <p>
//         <label htmlFor="contact-message">
//           <Text>Your Message</Text>
//           <textarea name="message" id="contact-message" required />
//         </label>
//       </p>
//       <p>
//         <Button>
//           <Text>Send</Text>
//         </Button>
//       </p>
//       <input type="hidden" name="form-name" value="contact-form" />
//     </form>
//   </Wrapper>
// )
