import React from 'react'
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import { darken, lighten } from 'polished'


const config = require('../../config')

const Wrapper = styled.header`
  background: no-repeat linear-gradient(
    45deg,
    ${props => darken(0.1, props.theme.colors.primary)},
    ${props => lighten(0.1, props.theme.colors.primary)}
  );
  background-image: url('/img/slide2.jpg');
  grid-column: 1 / -1;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 2rem 2rem 5rem 2rem;
  box-shadow: inset 0px -10px 30px 0px rgba(0, 0, 0, 0.1);
`

const Title = styled.h1`
  font-size: 2rem;
`

const Content = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;

  a {
    color: ${props => props.theme.colors.white};
    font-size: 1.2rem;
    &:hover {
      opacity: 0.85;
      color: ${props => props.theme.colors.white};
    }
  }
`

const Menu = ({ items }) => {
  return (
    <div>
      {items.map((item, i) => (
        <Button key={i}>
          <Link to={item.node.fields.slug}>{item.node.frontmatter.title}</Link>
        </Button>
      ))}
    </div>
  )
}

const Header = ({ data, language, children }) => (
  <Wrapper>
    <Title><Link to="/">{config.siteTitle}</Link></Title>
    <Menu items=
      {data.allMdx.edges
        .filter(menuItem => menuItem.node.frontmatter.language === language)} />
    <Content>{children}</Content>
  </Wrapper>
)

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  language: PropTypes.string.isRequired,  
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              language: PropTypes.isRequired,
              title: PropTypes.isRequired
            }).isRequired,
          }).isRequired
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

Header.defaultProps = {
  language: config.siteLanguage
}

export default props => (
  <StaticQuery
    render={ data => <Header data={data} {...props} />}
    query={ graphql`
      query HeaderQuery {
        allMdx(
          sort: {fields: [frontmatter___date], order: DESC},
          filter: {frontmatter: {
            menu: {eq: true},
          }}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                language
                title
              }
            }
          }
        }
      }
`}
  />
)
